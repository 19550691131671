import { withProps } from 'recompose';
import { get } from 'lodash';

export default withProps((props) => {
	const canonicalUrl = `${get(props, 'baseUrl', '')}${props.canonicalUrl}`;
	const pageSchema = {
		'@context': 'https://schema.org',
		'@graph': [
			{
				'@type': 'WebSite',
				'@id': 'https://www.sparefoot.com',
				url: 'https://www.sparefoot.com/',
				name: 'SpareFoot',
			},
			{
				'@type': 'WebPage',
				'@id': canonicalUrl,
				url: canonicalUrl,
				inLanguage: 'en',
				name: props.metaTitle,
				isPartOf: { '@id': 'https://www.sparefoot.com' },
				description: props.metaDescription,
			},
		],
	};

	const offerCatalogSchema = {
		'@context': 'https://schema.org',
		'@type': 'OfferCatalog',
		name: 'Storage Units',
		itemListElement: {
			'@type': 'Offer',
			name: props?.facility?.name,
			priceSpecification: {
				'@type': 'PriceSpecification',
				price: props?.facility?.cheapestPrice?.value,
				priceCurrency: 'USD',
				valueAddedTaxIncluded: 'False',
			},
		},
	};
	const mapSchema = {
		'@context': 'https://schema.org',
		'@type': 'Map',
		name: 'Facility Map',
		url: `https://www.google.com/maps?q=${props?.facility?.location?.latitude},${props?.facility?.location?.longitude}`,
	};

	const clpMapSchema = {
		'@context': 'https://schema.org/',
		'@type': 'Map',
		name: 'Sparefoot',
		url: props.staticMapUrl,
	};

	const stringifiedPageSchema = JSON.stringify(pageSchema);
	const stringifiedOfferCatalogSchema = JSON.stringify(offerCatalogSchema);
	const stringifiedMapSchema = JSON.stringify(mapSchema);
	const stringifiedClpMapSchema = JSON.stringify(clpMapSchema);
	return {
		pageSchema: stringifiedPageSchema,
		offerCatalogSchema: stringifiedOfferCatalogSchema,
		mapSchema: stringifiedMapSchema,
		clpMapSchema: stringifiedClpMapSchema,
	};
});
